<template>
  <div class="flex justify-end items-center">
    <el-button @click="$emit('cancel')">
      {{ $t('common.interactions.buttons.cancel') }}
    </el-button>
    <el-button type="primary" @click="$emit('save')">
      {{ $t('common.interactions.buttons.confirm') }}
    </el-button>
  </div>
</template>
