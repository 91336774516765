<template>
  <div
    v-loading="loading"
    class="select-holder product-select-holder"
    tabindex="0"
    @click="$emit('show-modal')"
  >
    <template v-if="shownItems && shownItems.length">
      <el-tag v-for="item in items" :key="item.id" class="mr-2" size="small">
        {{ item.name }}
      </el-tag>
    </template>
    <span
      v-else
      class="placeholder-text text-th-secondary-gray"
      v-text="placeholder"
    />
  </div>
</template>

<script>
import { v4 } from 'uuid'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    shownItems: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      required: true
    },
    limitItems: {
      type: Number,
      default: 4
    }
  },
  computed: {
    items() {
      const slicedItems = this.shownItems.slice(0, this.limitItems)
      const restCount = this.shownItems.length - this.limitItems
      const restElement =
        restCount > 0 ? [{ name: `+ ${restCount}`, id: v4() }] : []
      return [...slicedItems, ...restElement]
    }
  }
}
</script>

<style scoped>
.select-holder {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: var(--label-text-color);
  display: inline-block;
  min-height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 7px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}

.placeholder-text {
  padding-left: 8px;
  line-height: 1;
}
</style>
